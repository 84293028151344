import { Box, FormControl, FormLabel, HStack, Tooltip } from '@chakra-ui/react';
import { Icon } from '@jurnee/common/src/components/Icon';
import { useTranslation } from 'react-i18next';
import { RoleSelect as Select } from '../../components/RoleSelect';

interface Props {
  defaultValue: string;
  isDisabled: boolean;
  onChange(key: string): void;
}

export function RoleSelect({ defaultValue, isDisabled, onChange }: Props) {
  const { t } = useTranslation('employees');

  return (
    <FormControl id="roleKey">
      <HStack justifyContent="space-between">
        <FormLabel>{t('drawer.form.role.label')}</FormLabel>

        <Tooltip label={t('role.tooltip')} minW={360} size="md" whiteSpace="pre-line">
          <Box>
            <Icon icon="help" size={5} color="gray.400" position="absolute" top={0} right={0} />
          </Box>
        </Tooltip>
      </HStack>

      <Select onChange={onChange} defaultValue={defaultValue || ''} isDisabled={isDisabled} />
    </FormControl>
  );
}