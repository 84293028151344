import { popoverAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(popoverAnatomy.keys);

const baseStyle = definePartsStyle({
  content: {
    borderRadius: 4,
    padding: 5,
  },
  body: {
    padding: 0
  }
});

export const Popover = defineMultiStyleConfig({
  baseStyle
});