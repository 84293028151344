import { ExperienceJSON } from '@jurnee/common/src/entities/Experience';
import { PlaceJSON } from '@jurnee/common/src/entities/Place';

export function getPlaceLabel({ name, address }: Partial<PlaceJSON>) {
  const city = address.city && address.postalCode ? `${address.city}, ${address.postalCode}` : null;

  return city ? `${name} · ${city}` : name;
}

export function filterDuplicatePlaces(places: PlaceJSON[], experiences: ExperienceJSON[]) {
  const providerIds = experiences.flatMap(({ partner }) => {
    return partner ? partner.partnersProvidersInformation.map(({ providerId }) => providerId) : [];
  });

  return places.filter(({ id }) => !providerIds.includes(id));
}