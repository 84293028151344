import { HStack, Image, SimpleGrid, Text } from '@chakra-ui/react';
import { useMemo } from 'react';
import { Trans } from 'react-i18next';
import { PartnerProviderInformationJSON } from '../../../entities/PartnerProviderInformation';

interface Props {
  partnersProvidersInformation: PartnerProviderInformationJSON[];
}

export function PartnerProviderAvgRatings({ partnersProvidersInformation }: Props) {
  const list = useMemo(() => partnersProvidersInformation.filter(({ provider, ratingAverage, ratingCount }) => {
    return ratingAverage > 0 && ratingCount > 0 && ['GOOGLE_MAPS', 'TRIPADVISOR'].includes(provider);
  }), [partnersProvidersInformation]);

  return (
    <SimpleGrid w="100%" columns={2} spacing={1} mt={5}>
      {
        list.map(({ provider, ratingAverage, ratingCount }, idx) => {
          return (
            <HStack key={idx} spacing={2}>
              <Image src={`/assets/images/${provider.toLowerCase()}.png`} w={22} h={22} />

              <HStack spacing={1}>
                <Trans
                  i18nKey={`reviews:partnerProvider.avgRating_${provider}`}
                  values={{
                    rating: ratingAverage.toFixed(1),
                    count: ratingCount
                  }}
                  components={{
                    rating: <Text fontSize={14} color="black" fontWeight={700} />,
                    count: <Text fontSize={14} color="gray.400" />
                  }}
                />
              </HStack>
            </HStack>
          );
        })
      }
    </SimpleGrid>
  );
}