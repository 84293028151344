import { Button, ButtonProps, Divider, DividerProps, HStack, Input, NumberInputProps, useNumberInput } from '@chakra-ui/react';
import { InputProps } from 'react-select';

export function NumberInputStepper(props: Partial<NumberInputProps> & Omit<Partial<InputProps>, 'onChange'>) {
  const { getInputProps, getIncrementButtonProps, getDecrementButtonProps } = useNumberInput({ ...props, focusInputOnChange: false });

  const increment = getIncrementButtonProps();
  const decrement = getDecrementButtonProps();
  const input = getInputProps();

  const divider: DividerProps = { orientation: 'vertical', h: '14px' };
  const button: ButtonProps = { size:'xs', variant:'unstyled', fontWeight: 700, px: 4, minW: '30px', lineHeight: '14px' };

  return (
    <HStack w="160px" h="32px" borderRadius={4} border="1px solid" borderColor="gray.200" spacing={0} justifyContent="space-between">
      <Button {...decrement} {...button}>-</Button>
      <Divider {...divider} />
      <Input {...input} size="xs" w="50px" variant="unstyled" textAlign="center" />
      <Divider {...divider} />
      <Button {...increment} {...button}>+</Button>
    </HStack>
  );
}