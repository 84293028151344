import { Badge } from '@chakra-ui/react';
import { UserDetails } from '@jurnee/common/src/entities/User';
import { getUserRole } from '@jurnee/common/src/utils/user';
import { useTranslation } from 'react-i18next';

interface Props {
  user: UserDetails;
}

export function UserRole({ user }: Props) {
  const { t } = useTranslation('employees', { keyPrefix: 'role' });
  const role = getUserRole(user);

  if (!role) {
    return <Badge variant="gray">{t('PARTICIPANT')}</Badge>;
  }

  const variants = {
    ADMIN: 'pink',
    EVENT_MANAGER: 'blue',
    ORGANIZER: 'teal',
  };

  return <Badge variant={variants[role.key as keyof typeof variants]}>{t(`${role.key}`)}</Badge>;
}