import { Badge, Button, FormControl, FormLabel, HStack, Popover, PopoverBody, PopoverContent, PopoverTrigger, VStack } from '@chakra-ui/react';
import { Icon } from '@jurnee/common/src/components/Icon';
import { NumberInputStepper } from '@jurnee/common/src/components/NumberInputStepper';
import { KeyboardEvent, MouseEvent, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PrimaryButton } from 'src/components/buttons';
import { ExperiencesStateFilters } from '..';

const DEFAULT_PARTICIPANTS = 10;

interface Props {
  filters: Pick<ExperiencesStateFilters, 'participants'>;
  onChange(filters: Pick<ExperiencesStateFilters, 'participants'>): void;
}

export function Filters(props: Props) {
  const { t } = useTranslation(['experiences', 'common']);

  const [filters, setFilters] = useState({ participants: props.filters.participants ?? DEFAULT_PARTICIPANTS });

  const filtersCount = useMemo(() => getFiltersCount(), [props.filters]);
  const isApplyDisabled = useMemo(() => isNaN(filters.participants) || filters.participants < 1 || filters.participants === props.filters.participants, [filters.participants, props.filters.participants]);

  function onParticipantsChange(_: string, participants: number) {
    setFilters({ ...filters, participants: participants });
  }

  function onApply(e: MouseEvent | KeyboardEvent) {
    e.preventDefault();
    props.onChange(filters);
  }

  function onClear(e: MouseEvent) {
    e.preventDefault();

    const newFilters = { ...filters, participants: null } as Pick<ExperiencesStateFilters, 'participants'>;
    setFilters(newFilters);
    props.onChange(newFilters);
  }

  function getFiltersCount(): number {
    return Object.values(props.filters).filter(value => value !== null).length;
  }

  function onKeyDown(e: KeyboardEvent) {
    if (e.key === 'Enter' && !isApplyDisabled) {
      onApply(e);
    }
  }

  return (
    <Popover placement="bottom-end">
      <PopoverTrigger>
        <Button
          size="sm"
          bg="black"
          color="white"
          fontWeight={500}
          _hover={{ background: 'blackAlpha.700' }}
          _active={{ background: 'blackAlpha.800' }}
          leftIcon={<Icon icon="filters" size={4} color="white" />}
          rightIcon={filtersCount > 0 && <Badge variant="white" size="md">{filtersCount}</Badge>}
        >
          { t('header.filters.label') }
        </Button>
      </PopoverTrigger>
      <PopoverContent width="auto" onKeyDown={onKeyDown}>
        <PopoverBody>
          <VStack w="100%" spacing={4}>
            <FormControl id="filters">
              <FormLabel lineHeight="14px" mb="8px">{t('header.filters.participants')}</FormLabel>

              <NumberInputStepper
                step={1}
                defaultValue={filters.participants}
                min={1}
                onChange={onParticipantsChange}
              />
            </FormControl>

            <HStack w="100%" spacing={4}>
              <PrimaryButton size="sm" w="72px" colorScheme="black" onClick={onClear} isDisabled={filtersCount === 0}>
                { t('common:buttons.clear') }
              </PrimaryButton>

              <PrimaryButton size="sm" w="72px" onClick={onApply} isDisabled={isApplyDisabled}>
                { t('common:buttons.apply') }
              </PrimaryButton>
            </HStack>
          </VStack>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
}