import { DotMenu } from '@jurnee/common/src/components/DotMenu';
import { MenuItemWrapper } from '@jurnee/common/src/components/MenuItemWrapper';
import { UserDetails } from '@jurnee/common/src/entities/User';
import { ConfirmModal } from '@jurnee/common/src/modals/ConfirmModal';
import { getUserLabel } from '@jurnee/common/src/utils/user';
import { Trans, useTranslation } from 'react-i18next';

interface Props {
  organizer: UserDetails;
  onRemove(organizer: UserDetails): void;
}

export function OrganizerDotMenu({ organizer, onRemove }: Props) {
  const { t } = useTranslation('booking', { keyPrefix: 'organizers.table.dotMenu' });

  return (
    <DotMenu size="sm" color="black" placement="bottom-end">
      <ConfirmModal
        title={t('removeConfirmation.title')}
        message={<Trans i18nKey="booking:organizers.table.dotMenu.removeConfirmation.message" values={{ organizer: getUserLabel(organizer) }} shouldUnescape />}
        buttonLabel={t('remove')}
        onConfirm={() => onRemove(organizer)}
      >
        <MenuItemWrapper icon="trash" label={t('remove')} />
      </ConfirmModal>
    </DotMenu>
  );
}