import { BudgetJSON } from '@jurnee/common/src/entities/Budget';
import { UserDetails } from '@jurnee/common/src/entities/User';
import { isEventManager } from '@jurnee/common/src/utils/user';
import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../state';
import { budgetsAdapter } from './budgets.adapter';

export const budgetsSelectors = budgetsAdapter.getSelectors(
  (state: RootState) => state.budgets
);

export function getBudgetsFetchStatusSelector(state: RootState) {
  return state.budgets.status;
}

export function getBudgetsSelector(state: RootState) {
  return budgetsSelectors.selectAll(state);
}

export const getBudgetById = (id: BudgetJSON['id']) => {
  return (state: RootState) => budgetsSelectors.selectById(state, id);
};

const getBudgetsByIdsSelector = createSelector(
  [state => budgetsSelectors.selectAll(state), (_, ids: number[]) => ids],
  (budgets: BudgetJSON[], ids: number[]) => {
    return budgets.filter(budget=> ids.find(id => id === budget.id));
  }
);

export function getBudgetsByIds(ids: number[]) {
  return (state: RootState) => getBudgetsByIdsSelector(state, ids);
}

export const getBudgetsByOrganizerIdSelector = createSelector(
  [state => budgetsSelectors.selectAll(state), (_, organizer) => organizer],
  (budgets: BudgetJSON[], organizer: UserDetails) => {
    if (isEventManager(organizer)) {
      return budgets;
    }

    return budgets.filter(budget=> budget.budgetsOrganizers.map(({ userId }) => userId).includes(organizer.id));
  }
);

export const getBudgetsByDefinitionTypeSelector = createSelector(
  [state => budgetsSelectors.selectAll(state), (_, definitionType: BudgetJSON['definitionType']) => definitionType],
  (budgets: BudgetJSON[], definitionType: BudgetJSON['definitionType']) => {
    return budgets.filter(budget=> budget.definitionType === definitionType);
  }
);

export function getBudgetsByDefinitionType(definitionType: BudgetJSON['definitionType']) {
  return (state: RootState) => getBudgetsByDefinitionTypeSelector(state, definitionType);
}