import {
  Checkbox,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Text,
  Textarea,
  VStack
} from '@chakra-ui/react';
import { InviteEmployeesBody } from '@jurnee/common/src/dtos/users';
import { RoleKey } from '@jurnee/common/src/entities/Role';
import { TeamJSON } from '@jurnee/common/src/entities/Team';
import { sortAlphabeticallyBy } from '@jurnee/common/src/utils/arrays';
import { isValidEmail } from '@jurnee/common/src/utils/strings';
import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { RoleSelect } from 'src/components/RoleSelect';
import { RootState } from 'src/store/state';
import { teamsSelectors } from 'src/store/teams/teams.selectors';
import { PrimaryButton, SecondaryButton } from '../components/buttons';
import { TrackEventOptions, trackEvent } from '../store/analytics/analytics.thunks';
import { inviteEmployees } from '../store/employees/employees.thunks';

interface StateProps {
  approvalProcesses: RootState['approvalProcesses'];
  company: RootState['company'];
  teams: TeamJSON[];
  user: RootState['user'];
}

interface DispatchProps {
  trackEvent(opts: TrackEventOptions): void;
  inviteEmployees(payload: InviteEmployeesBody): void;
}

interface State {
  isOpen: boolean;
  approvalProcessId: number;
  roleKey: RoleKey;
  teamId: number;
  emailsList: string[];
}

type Props = DispatchProps & StateProps & WithTranslation;

class InviteEmployeesModal extends React.PureComponent<Props, State> {

  state: State = {
    isOpen: false,
    approvalProcessId: null,
    roleKey: null,
    teamId: null,
    emailsList: []
  };

  onClose = () => {
    this.setState({ isOpen: false });
  };

  onOpen = () => {
    this.setState({ isOpen: true });

    this.props.trackEvent({
      name: 'opened_invite_employees_modal'
    });
  };

  getEmailsList = (emails: string) => {
    const emailsList = emails.split(/\s|,/g).filter(isValidEmail);
    return [...new Set(emailsList)];
  };

  onChangeEmails = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const emailsList = this.getEmailsList(e.target.value);
    this.setState({ ...this.state, emailsList });
  };

  onInvite = () => {
    const users: InviteEmployeesBody['users'] = this.state.emailsList.map((email: string) => ({ email }));
    const payload: InviteEmployeesBody = { users };

    if (this.state.approvalProcessId) {
      payload.approvalProcessId = this.state.approvalProcessId;
    }

    if (this.state.teamId) {
      payload.teamId = this.state.teamId;
    }

    if (this.state.roleKey) {
      payload.roleKey = this.state.roleKey;
    }

    this.props.inviteEmployees(payload);
    this.onClose();
  };

  onToggleTeam = ({ target: { checked } }: React.ChangeEvent<HTMLInputElement>) => {
    const teams = sortAlphabeticallyBy(this.props.teams, 'name');

    this.setState({
      ...this.state,
      teamId: checked ? teams[0]?.id : null
    });
  };

  onToggleApprovalProcess = ({ target: { checked } }: React.ChangeEvent<HTMLInputElement>) => {
    const approvalProcesses = sortAlphabeticallyBy(this.props.approvalProcesses.list, 'name');

    this.setState({
      ...this.state,
      approvalProcessId: checked ? approvalProcesses[0]?.id : null
    });
  };

  onChangeRole = (key: string) => {
    this.setState({
      ...this.state,
      roleKey: key as State['roleKey'] || null
    });
  };

  onChangeTeam = (e: React.ChangeEvent<HTMLSelectElement>) => {
    this.setState({
      ...this.state,
      teamId: Number(e.target.value)
    });
  };

  onChangeApprovalProcess = (e: React.ChangeEvent<HTMLSelectElement>) => {
    this.setState({
      ...this.state,
      approvalProcessId: Number(e.target.value)
    });
  };

  renderRoleSelect() {
    return (
      <>
        <HStack w="100%" minH={8}>
          <Text w="50%">{this.props.t('modal.invite.role.label')}</Text>

          <RoleSelect w="50%" value={this.state.roleKey || ''} onChange={this.onChangeRole} />
        </HStack>

        <Text w="100%" color="gray.400">
          { this.props.t(`modal.invite.role.${this.state.roleKey || 'PARTICIPANT'}.helpText`) }
        </Text>
      </>
    );
  }

  get teamSelector() {
    if (!this.state.teamId) {
      return null;
    }

    const teams = sortAlphabeticallyBy(this.props.teams, 'name');

    return (
      <Select w="50%" size="sm" bg="white" value={this.state.teamId} onChange={this.onChangeTeam}>
        { teams.map(({ id, name }) => <option key={id} value={id}>{name}</option>) }
      </Select>
    );
  }

  renderTeamSelect() {
    if (this.props.teams.length === 0) {
      return null;
    }

    return (
      <HStack w="100%" minH={8}>
        <HStack w="50%" spacing={2}>
          <Checkbox colorScheme="blue" checked={!!this.state.teamId} onChange={this.onToggleTeam} />
          <Text>{this.props.t('modal.invite.team.label')}</Text>
        </HStack>

        { this.teamSelector }
      </HStack>
    );
  }

  get approvalProcessSelector() {
    if (!this.state.approvalProcessId) {
      return null;
    }

    const approvalProcesses = sortAlphabeticallyBy(this.props.approvalProcesses.list, 'name');

    return (
      <Select w="50%" size="sm" bg="white" value={this.state.approvalProcessId} onChange={this.onChangeApprovalProcess}>
        { approvalProcesses.map(({ id, name }) => <option key={id} value={id}>{name}</option>) }
      </Select>
    );
  }

  renderApprovalProcessSelect() {
    if (this.props.approvalProcesses.list.length === 0) {
      return null;
    }

    return (
      <HStack w="100%" minH={8}>
        <HStack w="50%" spacing={2}>
          <Checkbox colorScheme="blue" checked={!!this.state.approvalProcessId} onChange={this.onToggleApprovalProcess} />
          <Text>{this.props.t('modal.invite.approvalProcess.label')}</Text>
        </HStack>

        { this.approvalProcessSelector }
      </HStack>
    );
  }

  render() {
    return (
      <>
        <PrimaryButton size="sm" flexShrink={0} onClick={this.onOpen}>
          { this.props.t('people:invitePeople') }
        </PrimaryButton>

        <Modal isOpen={this.state.isOpen} onClose={this.onClose} size="xl">
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>
              { this.props.t('people:invitePeople') }

              <ModalCloseButton />
            </ModalHeader>

            <ModalBody>
              <VStack w="100%" spacing={5}>
                <Text>
                  { this.props.t('modal.invite.description') }
                </Text>
                <Textarea
                  onChange={this.onChangeEmails}
                  placeholder={this.props.t('modal.invite.emailsTextArea.placeholder')}
                  size="md"
                  height="140px"
                  focusBorderColor="gray.300"
                  resize="none"
                />

                <VStack w="100%" spacing={4}>
                  { this.renderRoleSelect() }

                  <VStack w="100%" spacing="10px">
                    { this.renderTeamSelect() }
                    { this.renderApprovalProcessSelect() }
                  </VStack>
                </VStack>
              </VStack>
            </ModalBody>

            <ModalFooter>
              <HStack justifyContent="space-between" w="100%">
                <SecondaryButton colorScheme="pink" size="sm" onClick={this.onClose}>
                  { this.props.t('common:buttons.close') }
                </SecondaryButton>

                <PrimaryButton colorScheme="teal" size="sm" onClick={this.onInvite} isDisabled={this.state.emailsList.length === 0}>
                  { this.props.t('common:buttons.apply') }
                </PrimaryButton>
              </HStack>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </>
    );
  }
}

function mapStateToProps(state: RootState): StateProps {
  return {
    approvalProcesses: state.approvalProcesses,
    company: state.company,
    teams: teamsSelectors.selectAll(state),
    user: state.user,
  };
}

const mapDispatchToProps: DispatchProps = {
  trackEvent,
  inviteEmployees
};

export default connect<StateProps, DispatchProps, Record<string, never>, RootState>(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation('employees')(InviteEmployeesModal));