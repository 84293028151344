import { Text, VStack } from '@chakra-ui/react';
import { ApprovalRequestCreateBody } from '@jurnee/common/src/dtos/approvalRequests';
import { ApprovalProcessJSON } from '@jurnee/common/src/entities/ApprovalProcess';
import { ApprovalRequestJSON } from '@jurnee/common/src/entities/ApprovalRequest';
import { ApprovalRuleJSON } from '@jurnee/common/src/entities/ApprovalRule';
import { BookingJSON } from '@jurnee/common/src/entities/Booking';
import { BookingInvoiceJSON } from '@jurnee/common/src/entities/BookingInvoice';
import { UserJSON } from '@jurnee/common/src/entities/User';
import { isApprovalProcessReviewer } from '@jurnee/common/src/utils/approvalProcesses';
import { getBookingInvoicesTotalDetails } from '@jurnee/common/src/utils/bookingInvoices';
import { isEventManager } from '@jurnee/common/src/utils/user';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import BookingPriceDetailsCard from 'src/components/BookingPriceDetailsCard';
import { ApprovalResponses } from 'src/pages/BookingDetails/ApprovalResponses';
import { getUserSelector } from 'src/store/user/user.selectors';
import { PoNumber } from '../PoNumber';
import { ApprovalProcessSelect } from './ApprovalProcessSelect';
import { ApprovalRules } from './ApprovalRules';
import { Comment } from './Comment';
import { SubmitButton } from './SubmitButton';

interface Props {
  approvalProcesses: ApprovalProcessJSON[];
  approvalRequest: ApprovalRequestJSON | null;
  bookingsInvoices: BookingInvoiceJSON[];
  processNumber: BookingJSON['processNumber'];
  users: UserJSON[];
  disabled: boolean;
  onSubmit(data: ApprovalRequestCreateBody): Promise<void>;
}

export function ApprovalRequestCard(props: Props) {
  const { t } = useTranslation('approvalRequests');

  const user = useSelector(getUserSelector);
  const approvalProcesses = useMemo(() => props.approvalProcesses.filter(({ approvalRules }) => approvalRules.length > 0), [props.approvalProcesses]);
  const priceDetails = useMemo(() => getBookingInvoicesTotalDetails(props.bookingsInvoices), [props.bookingsInvoices]);

  const [processNumber, setProcessNumber] = useState(props.processNumber);
  const [comment, setComment] = useState(null);
  const [approvalProcessId, setApprovalProcessId] = useState(props.approvalRequest?.approvalProcessId || (approvalProcesses.length === 1 ? approvalProcesses[0].id : null));

  const approvalProcess = useMemo(() => approvalProcesses.find(({ id }) => id === approvalProcessId), [approvalProcessId, approvalProcesses]);
  const isSelfApprovalEnabled = useMemo(() => approvalProcess && isApprovalProcessReviewer(approvalProcess, user.id), [approvalProcess, user]);
  const selfApprovalRules = useMemo(() => approvalProcess ? approvalProcess.approvalRules.filter(({ approverId }) => approverId !== user.id) as ApprovalRuleJSON[] : [], [approvalProcess, user]);
  const isProcessNumberRequired = useMemo(() => isSelfApprovalEnabled && approvalProcess.processNumberRequired, [approvalProcess, isSelfApprovalEnabled]);

  return (
    <VStack w="100%" spacing={5}>
      <PoNumber
        processNumber={processNumber}
        isCommitment={!!props.approvalRequest}
        isProcessNumberRequired={isProcessNumberRequired}
        onChange={setProcessNumber}
      />

      {
        props.approvalRequest ? (
          <VStack w="100%" alignItems="flex-start" spacing="10px">
            <Text fontSize={14} lineHeight="16px" fontWeight={700}>
              { t('form.requireApproval.label') }
            </Text>

            <ApprovalResponses
              approvalRequest={props.approvalRequest}
              users={props.users}
            />
          </VStack>
        ) : (
          <>
            {
              isEventManager(user) &&
                <ApprovalProcessSelect
                  approvalProcesses={approvalProcesses}
                  defaultValue={approvalProcess?.id}
                  onChange={setApprovalProcessId}
                />
            }
            {
              isSelfApprovalEnabled ? (
                <VStack w="100%" alignItems="flex-start" spacing="10px">
                  <Text w="100%" fontSize={14} lineHeight="16px" fontWeight={700}>
                    { t('form.selfApproval.label') }
                  </Text>

                  {
                    selfApprovalRules.length > 0 &&
                      <>
                        <Text w="100%" fontSize={14} lineHeight="16px" color="gray.400">
                          { t('form.selfApproval.otherApprovers') }
                        </Text>

                        <ApprovalRules
                          approvalRules={selfApprovalRules}
                          users={props.users}
                        />
                      </>
                  }
                </VStack>
              ) : (
                approvalProcess &&
                  <VStack w="100%" alignItems="flex-start" spacing="10px">
                    <Text fontSize={14} lineHeight="16px" fontWeight={700}>
                      { t('form.requireApproval.label') }
                    </Text>

                    <ApprovalRules
                      approvalRules={approvalProcess.approvalRules}
                      users={props.users}
                    />

                    <Comment onChange={setComment} />
                  </VStack>
              )
            }
          </>
        )
      }

      <BookingPriceDetailsCard
        subtotal={priceDetails.subtotal}
        totalTax={priceDetails.totalTax}
        totalDiscount={priceDetails.totalDiscount}
        total={priceDetails.total}
        currency={priceDetails.currency}
        showCurrencyWarning={true}
      />

      <SubmitButton
        approvalRequest={props.approvalRequest}
        isSelfApprovalEnabled={isSelfApprovalEnabled}
        processNumber={processNumber}
        disabled={props.disabled || !approvalProcess}
        isProcessNumberRequired={isProcessNumberRequired}
        onSubmit={() => approvalProcess && props.onSubmit({ approvalProcessId: approvalProcess.id, processNumber, comment })}
      />
    </VStack>
  );
}