import { useToast } from '@chakra-ui/react';
import { BookingInvoiceJSON } from '@jurnee/common/src/entities/BookingInvoice';
import { sortByDate } from '@jurnee/common/src/utils/arrays';
import { getBookingInvoiceDateFromStatus, getInvoicesTotal } from '@jurnee/common/src/utils/bookingInvoices';
import { getErrorToast } from '@jurnee/common/src/utils/toasts';
import { useTranslation } from 'react-i18next';
import bookingApi from 'src/api/bookings';
import BillingDocumentLine from 'src/components/BillingDocumentLine';

interface Props {
  bookingsInvoices: BookingInvoiceJSON[];
}

export function Invoices(props: Props) {
  const { t } = useTranslation('billing');
  const toast = useToast();

  const bookingsInvoices = sortByDate(props.bookingsInvoices.filter(({ status }) => ['DRAFT', 'OPEN', 'PAID'].includes(status)), 'finalizedAt', 'desc');

  async function onClickInvoice(bookingInvoice: BookingInvoiceJSON) {
    try {
      const payload = await bookingApi.getBookingInvoiceFileUrl(bookingInvoice.bookingId, bookingInvoice.providerInvoiceId);

      window.open(payload.providerInvoiceFileUrl, '_blank');
    } catch(e) {
      toast(getErrorToast(t('document.toasts.error')));
    }
  };

  return bookingsInvoices.map(bookingInvoice => {
    const total = getInvoicesTotal([bookingInvoice]);
    const date = !bookingInvoice.providerParentInvoiceId ? getBookingInvoiceDateFromStatus(bookingInvoice) : null;
    const onClick = ['OPEN', 'PAID'].includes(bookingInvoice.status) && !bookingInvoice.providerParentInvoiceId ? () => onClickInvoice(bookingInvoice) : null;

    return <BillingDocumentLine
      key={bookingInvoice.providerInvoiceId}
      type='invoice'
      total={total}
      currency={bookingInvoice.currency}
      status={bookingInvoice.providerParentInvoiceId ? 'PAID' : bookingInvoice.status}
      date={date}
      linkLabel={t('common:buttons.download')}
      onClick={onClick}
    />;
  });
}