import { DotMenu } from '@jurnee/common/src/components/DotMenu';
import { MenuItemWrapper } from '@jurnee/common/src/components/MenuItemWrapper';
import { BookingParticipantJSON } from '@jurnee/common/src/entities/BookingParticipant';
import { ConfirmModal } from '@jurnee/common/src/modals/ConfirmModal';
import { Trans, useTranslation } from 'react-i18next';
import EditBookingParticipantDrawer from 'src/drawers/EditBookingParticipantDrawer';

interface Props {
  bookingParticipant: BookingParticipantJSON;
  onRemove(bookingParticipant: BookingParticipantJSON): void;
  onChange(): void;
}

export function ParticipantDotMenu({ bookingParticipant, onRemove, onChange }: Props) {
  const { t } = useTranslation('registration', { keyPrefix: 'dotMenu' });

  return (
    <DotMenu size="sm" color="black" placement="bottom-end">
      <EditBookingParticipantDrawer bookingParticipant={bookingParticipant} onChange={onChange}>
        <MenuItemWrapper icon="edit" label={t('edit')} />
      </EditBookingParticipantDrawer>

      <ConfirmModal
        title={t('removeConfirmation.title')}
        message={<Trans i18nKey="registration:dotMenu.removeConfirmation.message" values={{ email: bookingParticipant.participant.email }} shouldUnescape />}
        buttonLabel={t('remove')}
        onConfirm={() => onRemove(bookingParticipant)}
      >
        <MenuItemWrapper icon="trash" label={t('remove')} />
      </ConfirmModal>
    </DotMenu>
  );
}