import { Box, HStack, Input } from '@chakra-ui/react';
import AddressSearch, { CitySearchParams } from '@jurnee/common/src/components/AddressSearch';
import { Icon } from '@jurnee/common/src/components/Icon';
import { isEmpty } from '@jurnee/common/src/utils/strings';
import { KeyboardEvent, useState } from 'react';

export interface SearchBarParams {
  cityParams: CitySearchParams;
  query: string;
}

interface Props {
  query: string;
  defaultCityValue: string;
  placeholder: string;
  hasCitySearch: boolean;
  onSubmit(data: SearchBarParams): void;
}

export function SearchBar(props: Props) {
  const [cityParams, setCityParams] = useState<CitySearchParams>();
  const [query, setQuery] = useState(props.query);

  function onInputClear() {
    if (isEmpty(query)) {
      return;
    }

    setQuery('');
    props.onSubmit({ cityParams, query: '' });
  };

  function onCityChange(newCityParams: CitySearchParams) {
    setCityParams(newCityParams);
    props.onSubmit({ cityParams: newCityParams, query });
  };

  function onEnterKey(event: KeyboardEvent) {
    if (event.key === 'Enter' && !isEmpty(query)) {
      props.onSubmit({ cityParams, query });
    }
  };

  return (
    <HStack spacing={5}>
      <HStack
        w="265px"
        h="32px"
        spacing={3}
        py={2}
        px={3}
        bgColor="white"
        border="1px solid"
        borderColor="gray.200"
        borderRadius={4}
        _hover={{ borderColor:'gray.300' }}
      >
        <Icon
          color="gray.500"
          icon="search"
          size={4}
        />
        <Input
          fontSize={14}
          fontWeight={500}
          onChange={e => setQuery(e.target.value)}
          onKeyDown={onEnterKey}
          placeholder={props.placeholder}
          _placeholder={{ color: 'gray.500' }}
          variant="unstyled"
          defaultValue={props.query}
        />
        <Icon
          color="gray.200"
          icon="circleCloseLine"
          size={4}
          onClick={onInputClear}
          cursor="pointer"
          _hover={{ color: 'gray.500' }}
          _active={{ color: 'gray.700' }}
        />
      </HStack>

      {
        props.hasCitySearch &&
            <Box w="265px" h="32px">
              <AddressSearch
                onChange={onCityChange}
                defaultValue={props.defaultCityValue}
                variant="searchBar"
                types={['(cities)']}
              />
            </Box>
      }
    </HStack>
  );
}