import { HStack, LinkBox, LinkOverlay, Text } from '@chakra-ui/react';
import { BookingJSON, BookingRelationshipsJSON } from '@jurnee/common/src/entities/Booking';
import { getBookingName, getBookingStatus, getMainBookingRelation, getOrganizerLabel } from '@jurnee/common/src/utils/bookings';
import { getItemTimezone } from '@jurnee/common/src/utils/bookingsItems';
import { convertToTimezone, formatTimeShort, getCurrentTimeZone } from '@jurnee/common/src/utils/dates';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import StatusLabel from 'src/components/Booking/StatusLabel';
import { filterRelationshipsByBookingId } from 'src/utils/booking';

interface Props {
  booking: BookingJSON;
  relationships: BookingRelationshipsJSON;
}

export default function BookingLine(props: Props) {
  const { t } = useTranslation('booking');
  const bookingItem = getMainBookingRelation(props.booking);
  const organizerLabel = getOrganizerLabel(props.booking.bookingsOrganizers, props.relationships.users);

  const relationships = filterRelationshipsByBookingId(props.booking.id, props.relationships);
  const status = getBookingStatus(props.booking, relationships);
  const statusLabel = <StatusLabel status={status} w="20%" minW={130} textAlign="right" noOfLines={1} />;
  const timezone = getItemTimezone(bookingItem);
  const date = formatTimeShort(convertToTimezone(bookingItem.from, timezone, getCurrentTimeZone()));

  return (
    <LinkBox w="100%">
      <HStack
        w="100%"
        px={2}
        py="5px"
        spacing={3}
        cursor="pointer"
        _hover={{ bgColor: 'gray.50', borderRadius: 4 }}
      >
        <HStack w="40%" spacing={2}>
          <Text minW="44px" fontSize={14} fontWeight={700} noOfLines={1}>{date}</Text>

          <LinkOverlay to={`/bookings/${props.booking.id}`} as={Link}>
            <Text fontSize={14} noOfLines={1}>
              { getBookingName(props.booking) }
            </Text>
          </LinkOverlay>
        </HStack>

        <Text w="25%" fontSize={14} noOfLines={1} color="gray.400">
          { organizerLabel }
        </Text>

        <Text w="15%" fontSize={14} noOfLines={1} color="gray.400">
          { t('participants', { count: bookingItem.participants }) }
        </Text>

        { statusLabel }
      </HStack>
    </LinkBox>
  );

}