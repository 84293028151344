import { Table, TableContainer, Tbody, Td, Text, Th, Thead, Tr } from '@chakra-ui/react';
import { DotMenu } from '@jurnee/common/src/components/DotMenu';
import { EmptyState } from '@jurnee/common/src/components/EmptyState';
import { MenuItemWrapper } from '@jurnee/common/src/components/MenuItemWrapper';
import { BookingJSON } from '@jurnee/common/src/entities/Booking';
import { ExternalCostJSON } from '@jurnee/common/src/entities/ExternalCost';
import { ConfirmModal } from '@jurnee/common/src/modals/ConfirmModal';
import { getEntityKey } from '@jurnee/common/src/utils/core';
import { formatDate } from '@jurnee/common/src/utils/dates';
import { formatPrice } from '@jurnee/common/src/utils/prices';
import { getUserLabel } from '@jurnee/common/src/utils/user';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { PrimaryButton } from 'src/components/buttons';
import { ExternalCostDrawer } from 'src/drawers/ExternalCostDrawer';
import { getEmployeeByIdSelector } from 'src/store/employees/employees.selectors';

interface Props {
  booking: BookingJSON;
  externalCosts: ExternalCostJSON[];
  onOpenDocument(externalCost: ExternalCostJSON): void;
  onDelete(externalCost: ExternalCostJSON): void;
  onChange(): void;
}

interface ExternalCostProps {
  externalCost: ExternalCostJSON;
}

function EditMenu(props: ExternalCostProps & Props) {
  const { t } = useTranslation('externalCosts', { keyPrefix: 'dotMenu' });
  const { externalCost, onOpenDocument, onDelete } = props;

  return (
    <DotMenu size="sm" color="black" placement="bottom-end">
      <ExternalCostDrawer key={getEntityKey(externalCost)} booking={props.booking} externalCost={externalCost} onChange={props.onChange}>
        <MenuItemWrapper icon="edit" label={t('edit')} />
      </ExternalCostDrawer>

      { externalCost.path && <MenuItemWrapper icon="externalLink" label={t('openDocument')} onClick={() => onOpenDocument(externalCost)} /> }

      <ConfirmModal
        title={t('deleteConfirmation.title')}
        message={<Trans i18nKey="externalCosts:dotMenu.deleteConfirmation.message" values={{ description: externalCost.description }} shouldUnescape />}
        buttonLabel={t('delete')}
        onConfirm={() => onDelete(externalCost)}
      >
        <MenuItemWrapper icon="trash" label={t('delete')} />
      </ConfirmModal>
    </DotMenu>
  );
}

function Header() {
  const { t } = useTranslation('externalCosts', { keyPrefix: 'table' });

  return (
    <Thead>
      <Tr>
        <Th w="20%">{t('description')}</Th>
        <Th w="20%">{t('supplier')}</Th>
        <Th w="20%">{t('owner')}</Th>
        <Th w="15%">{t('eventDate')}</Th>
        <Th w="15%">{t('paymentDate')}</Th>
        <Th textAlign="right">{t('amount')}</Th>
        <Th w={8}></Th>
      </Tr>
    </Thead>
  );
}

function UserLabel({ userId }: Pick<ExternalCostJSON, 'userId'>) {
  const user = useSelector(state => getEmployeeByIdSelector(state, userId));

  return <Text>{getUserLabel(user)}</Text>;
}

function Body(props: Props) {
  const { t } = useTranslation('externalCosts');

  if (props.externalCosts.length === 0) {
    return (
      <Tbody>
        <Tr>
          <Td p={16} colSpan={7}>
            <EmptyState
              icon="listSearch"
              heading={t('emptyState.heading')}
              description={t('emptyState.description')}
            >
              <ExternalCostDrawer booking={props.booking} onChange={props.onChange}>
                <PrimaryButton size="sm" colorScheme="black" flexShrink={0}>
                  { t('buttons.addCost') }
                </PrimaryButton>
              </ExternalCostDrawer>
            </EmptyState>
          </Td>
        </Tr>
      </Tbody>
    );
  }

  return (
    <Tbody>
      {
        props.externalCosts.map(externalCost => {
          return (
            <Tr key={externalCost.id}>
              <Td><Text>{externalCost.description}</Text></Td>
              <Td><Text>{externalCost.supplier}</Text></Td>
              <Td><UserLabel userId={externalCost.userId} /></Td>
              <Td><Text>{formatDate(new Date(externalCost.eventDate))}</Text></Td>
              <Td><Text>{formatDate(new Date(externalCost.paymentDate))}</Text></Td>
              <Td textAlign="right"><Text>{formatPrice(externalCost.amount, externalCost.currency)}</Text></Td>
              <Td pl={0} pr={3}><EditMenu externalCost={externalCost} {...props} /></Td>
            </Tr>
          );
        })
      }
    </Tbody>
  );
}

export default function ExternalCostsTable(props: Props) {
  return (
    <TableContainer w="100%">
      <Table>
        <Header />
        <Body {...props} />
      </Table>
    </TableContainer>
  );
}