import { Badge, Box, HStack, Spacer, Text } from '@chakra-ui/react';
import Avatar from '@jurnee/common/src/components/Avatar';
import { DotMenu } from '@jurnee/common/src/components/DotMenu';
import { MenuItemWrapper } from '@jurnee/common/src/components/MenuItemWrapper';
import { BudgetJSON } from '@jurnee/common/src/entities/Budget';
import { BudgetOrganizerJSON } from '@jurnee/common/src/entities/BudgetOrganizer';
import { UserDetails } from '@jurnee/common/src/entities/User';
import { getUserLabel } from '@jurnee/common/src/utils/user';
import { useTranslation } from 'react-i18next';

interface OrganizerProps {
  budget: BudgetJSON;
  user: UserDetails;
  budgetOrganizer: Pick<BudgetOrganizerJSON, 'userId' | 'isOwner'>;
  onRemove(user: UserDetails): void;
  onBudgetOrganizerChange(data: Pick<BudgetOrganizerJSON, 'userId' | 'isOwner'>): void;
}

export function Organizer({ budgetOrganizer, user, onRemove, onBudgetOrganizerChange }: OrganizerProps) {
  const { t } = useTranslation('settings', { keyPrefix: 'budgets.drawer.form.users.GLOBAL'});

  return (
    <Box py={1} pr={1} w="100%">
      <HStack minH={8} spacing="10px">
        <HStack w="100%" spacing="10px">
          <Avatar user={user} borderRadius={4} />
          <Text noOfLines={1} wordBreak="break-all">{getUserLabel(user)}</Text>
        </HStack>

        <Spacer />

        <HStack spacing={1}>
          { budgetOrganizer.isOwner && <Badge variant="black" size="md">{t('owner')}</Badge> }

          <DotMenu size="sm" color="black" placement="bottom-end">
            {
              budgetOrganizer.isOwner ? (
                <MenuItemWrapper
                  icon="squareRoundedMinus"
                  label={t('dotMenu.removeFromOwners')}
                  onClick={() => onBudgetOrganizerChange({ ...budgetOrganizer, isOwner: false })}
                />
              ) : (
                <MenuItemWrapper
                  icon="squareRoundedPlus"
                  label={t('dotMenu.addAsOwner')}
                  onClick={() => onBudgetOrganizerChange({ ...budgetOrganizer, isOwner: true })}
                />
              )
            }

            <MenuItemWrapper icon="trash" label={t('dotMenu.removeOrganizer')} onClick={() => onRemove(user)} />
          </DotMenu>
        </HStack>
      </HStack>
    </Box>
  );
}