import ExperienceEntity, { Experience, ExperienceJSON, ExperienceRelationshipsJSON, ExperiencesSearchParams, ExperiencesSearchQuery, ExperienceUpdate } from '../entities/Experience';
import ImageEntity from '../entities/Image';
import { getMaxThreshold, isCustomRequestProduct, isParticipantsRangeValid, sortByPackagePrice } from './products';
import { sanitize } from './strings';

export const EXPERIENCE_MIN_IMAGES = 4;
export const EXPERIENCE_MIN_DESCRIPTION_LENGTH = 500;
export const EXPERIENCE_MIN_PRODUCTS = 1;

export function getExperienceTypeLabel(experience: Experience | ExperienceEntity) {
  switch (experience.type) {
  case 'BAR': return 'Bar';
  case 'BOX_SHIPPING': return 'Box shipping';
  case 'CATERING': return 'Catering';
  case 'IN_OFFICE': return 'In Office';
  case 'IN_PERSON': return 'In Person';
  case 'MEETING_ROOM': return 'Meeting room';
  case 'RESTAURANT': return 'Restaurant';
  case 'VIRTUAL': return 'Virtual';
  case 'VENUE': return 'Venue';
  default: return 'In Person';
  }
}

export function getExperiencePath({ id, type }: Pick<ExperienceEntity, 'id' | 'type'>): string {
  switch (type) {
  case 'BAR': return `/experiences/bar/${id}`;
  case 'BOX_SHIPPING': return `/experiences/box-shipping/${id}`;
  case 'CATERING': return `/experiences/catering/${id}`;
  case 'IN_OFFICE': return `/experiences/in-office/${id}`;
  case 'IN_PERSON': return `/experiences/in-person/${id}`;
  case 'MEETING_ROOM': return `/experiences/meeting-room/${id}`;
  case 'RESTAURANT': return `/experiences/restaurant/${id}`;
  case 'VIRTUAL': return `/experiences/virtual/${id}`;
  case 'VENUE': return `/experiences/venues/${id}`;
  default: return `/experiences/in-person/${id}`;
  }
}

export function isVirtual({ type }: Pick<ExperienceJSON, 'type'>) {
  return type === 'VIRTUAL';
}

export function isBoxShipping({ type }: Pick<ExperienceJSON, 'type'>) {
  return type === 'BOX_SHIPPING';
}

export function isGeneric({ partner }: Pick<ExperienceJSON, 'partner'>) {
  return partner === null;
}

export function isLocatedAtPartnerPlace(experience: Pick<ExperienceEntity, 'type' | 'partner'>) {
  if (['BOX_SHIPPING', 'CATERING', 'IN_OFFICE', 'VIRTUAL'].includes(experience.type)) {
    return false;
  }

  return !isGeneric(experience);
}

export function buildExperiencesSearchParams(query: ExperiencesSearchQuery): ExperiencesSearchParams {
  const params: ExperiencesSearchParams = {};

  if (query.participants) {
    params.participants = Number(query.participants);
  }

  if (query.languageId) {
    params.languageId = Number(query.languageId);
  }

  if (query.price && query.currency) {
    params.price = Number(query.price);
    params.currency = query.currency;
  }

  if (query.type) {
    params.type = query.type;
  }

  if (Array.isArray(query.tagIds) && query.tagIds.length > 0) {
    params.tagIds = query.tagIds.filter(tagId => !isNaN(Number(tagId))).map(tagId => Number(tagId));
  }

  if (query.query) {
    params.query = query.query;
  }

  if (query.lat && query.long && query.radius) {
    params.location = {
      circle: {
        lat: Number(query.lat),
        long: Number(query.long),
        radius: Number(query.radius)
      }
    };
  }

  if (query.isCompanyRecommended === 'true') {
    params.isCompanyRecommended = true;
  }

  return params;
}

export function getExperienceImage(experience: Pick<ExperienceEntity, 'experiencesImages'>): ImageEntity | null {
  const [experienceImage] = experience.experiencesImages;

  if (!experienceImage) {
    return null;
  }

  return experienceImage.image;
}

export function hasParticipantsOrPriceFilters({ price, currency, participants }: ExperiencesSearchParams) {
  return (currency && typeof price === 'number') || typeof participants === 'number';
}

export function isCustomRequestEnabled(experience: ExperienceJSON) {
  return experience.products.some(isCustomRequestProduct);
}

export function getExperienceMaxParticipants({ products }: ExperienceJSON) {
  return Math.max(...products.map(getMaxThreshold));
}

export function isCompanyRecommended({ partner }: ExperienceJSON, companyRecommendedPartners: ExperienceRelationshipsJSON['companyRecommendedPartners']) {
  if (!partner) {
    return false;
  }

  return companyRecommendedPartners.some(({ partnerId }) => partnerId === partner.id);
}

export function hasMap(experience: ExperienceJSON) {
  if (!['BAR', 'IN_PERSON', 'MEETING_ROOM', 'RESTAURANT', 'VENUE'].includes(experience.type)) {
    return false;
  }

  if (!experience.partner?.address) {
    return false;
  }

  if (!experience.partner?.address.lat || !experience.partner?.address.long) {
    return false;
  }

  return true;
}

export function getDefaultProductId(experience: ExperienceJSON, participants: number) {
  if (experience.products.length === 0) {
    return null;
  }

  const products = experience.products.filter(product =>
    !isCustomRequestProduct(product) &&
      isParticipantsRangeValid(product, participants)
  );

  if (products.length > 0) {
    const sortedProducts = sortByPackagePrice(products, participants);

    return sortedProducts[0].id;
  }

  return experience.products[0].id;
}
export function isDescriptionValid(experience: ExperienceEntity) {
  return experience.description.length >= EXPERIENCE_MIN_DESCRIPTION_LENGTH;
}

export function isImagesCountValid(experience: ExperienceEntity) {
  return experience.experiencesImages.length >= EXPERIENCE_MIN_IMAGES;
}

export function isProductCountValid(experience: ExperienceEntity) {
  return experience.products.length >= EXPERIENCE_MIN_PRODUCTS;
}

export function cleanData<T extends ExperienceUpdate>(data: T): T {
  if (typeof data.description === 'string') {
    data.description = sanitize(data.description);
  }

  return data;
}