import { FormControl, Textarea } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

interface Props {
  onChange(comment: string): void;
}

export function Comment({ onChange }: Props) {
  const { t } = useTranslation('approvalRequests');

  return (
    <FormControl id="comment">
      <Textarea
        name="comment"
        size="sm"
        minHeight="55px"
        fontSize="14px"
        resize="none"
        placeholder={t('form.comment.placeholder')}
        _placeholder={{ color: 'gray.400' }}
        onChange={({ target }) => onChange(target.value)}
        _disabled={{ color: 'gray.400', bgColor: 'gray.50', cursor: 'not-allowed' }}
      />
    </FormControl>
  );
}