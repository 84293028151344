import { HStack, Link, Text, VStack } from '@chakra-ui/react';
import { BookingInvoiceJSON } from '@jurnee/common/src/entities/BookingInvoice';
import { Currency } from '@jurnee/common/src/entities/Currency';
import { QuoteJSON } from '@jurnee/common/src/entities/Quote';
import { ConfirmModal } from '@jurnee/common/src/modals/ConfirmModal';
import { formatDate, getCurrentTimeZone } from '@jurnee/common/src/utils/dates';
import { formatPrice } from '@jurnee/common/src/utils/prices';
import { invoice_status, quotes_status } from '@prisma/client';
import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { BookingInvoiceStatus } from './Status/BookingInvoiceStatus';
import { QuoteStatus } from './Status/QuoteStatus';

interface Props extends WithTranslation {
  type: 'quote' | 'invoice';
  total: number;
  currency: Currency;
  status: invoice_status | quotes_status;
  date: Date;
  linkLabel: string;
  deleteLinkLabel?: string;
  sublabel?: string;
  onClick(): void;
  onDelete?(): void;
}

class BillingDocumentLine extends React.Component<Props> {

  get sublabel() {
    if (!this.props.sublabel) {
      return null;
    }

    return <Text variant='sublabel'>{this.props.sublabel}</Text>;
  }

  get hasSubline() {
    return this.dateLabel || this.downloadLink || this.deleteLink;
  }

  get dateLabel() {
    if (!this.props.date) {
      return null;
    }

    const date = formatDate(new Date(this.props.date), {
      displayTime: true,
      displayTimeZone: true,
      timeZone: getCurrentTimeZone()
    });

    return this.props.t(`${this.props.type}s.${this.props.status}.dateLabel`, { date });
  }

  get downloadLink() {
    if (!this.props.onClick) {
      return null;
    }

    return (
      <Link fontSize="14px" lineHeight="14px" cursor="pointer" color='blue.400' _hover={{ color: 'blue.600' }} onClick={this.props.onClick}>
        { this.props.linkLabel }
      </Link>
    );
  }

  get deleteLink() {
    if (!this.props.onDelete) {
      return null;
    }

    return (
      <ConfirmModal
        title={this.props.t('quotes.confirmationModal.delete.title')}
        message={this.props.t('quotes.confirmationModal.delete.message')}
        buttonLabel={this.props.t('common:buttons.delete')}
        onConfirm={this.props.onDelete}
      >
        <Link fontSize="14px" lineHeight="14px" cursor="pointer" color='pink.400' _hover={{ color: 'pink.600' }}>
          { this.props.deleteLinkLabel }
        </Link>
      </ConfirmModal>
    );
  }

  get status() {
    if (this.props.type === 'quote') {
      return <QuoteStatus status={this.props.status as QuoteJSON['status']} />;
    }

    return <BookingInvoiceStatus status={this.props.status as BookingInvoiceJSON['status']} />;
  }

  render() {
    return (
      <VStack w="100%" alignItems="flex-start" py="10px" pl={3} spacing={2} borderLeft="4px solid" borderColor="gray.200">
        <HStack w="100%" justifyContent="space-between">
          <Text fontSize={14} variant='label'>{formatPrice(this.props.total, this.props.currency)}</Text>

          { this.status }
        </HStack>

        { this.sublabel }

        {
          this.hasSubline &&
            <HStack w="100%" justifyContent="space-between">
              <Text variant='sublabel'>{this.dateLabel}</Text>

              <HStack spacing={5}>
                { this.downloadLink }

                { this.deleteLink }
              </HStack>
            </HStack>
        }
      </VStack>
    );
  }
}

export default withTranslation('booking')(BillingDocumentLine);