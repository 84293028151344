import { FormControl, FormLabel, Select } from '@chakra-ui/react';
import { ApprovalProcessJSON } from '@jurnee/common/src/entities/ApprovalProcess';
import { sortAlphabeticallyBy } from '@jurnee/common/src/utils/arrays';
import { ChangeEvent, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  approvalProcesses: ApprovalProcessJSON[];
  defaultValue: ApprovalProcessJSON['id'];
  onChange(approvalProcessId: ApprovalProcessJSON['id']): void;
}

export function ApprovalProcessSelect({ approvalProcesses, defaultValue, ...props }: Props) {
  const { t } = useTranslation('approvalRequests', { keyPrefix: 'form.approvalProcess' });

  const options = useMemo(
    () => sortAlphabeticallyBy(approvalProcesses, 'name').map(({ id, name }) => (
      <option key={id} value={id}>{name}</option>
    )),
    [approvalProcesses]
  );

  function onChange({ target }: ChangeEvent<HTMLSelectElement>) {
    props.onChange(Number(target.value));
  }

  return (
    <FormControl id="approvalProcessId">
      <FormLabel lineHeight="16px" mb={2}>{t('label')}</FormLabel>

      <Select size="sm" onChange={onChange} defaultValue={defaultValue}>
        <option key={0} value={0}>{t('placeholder')}</option>
        { options }
      </Select>
    </FormControl>
  );
}