import { HStack, VStack } from '@chakra-ui/react';
import { BudgetSelect } from '@jurnee/common/src/components/BudgetSelect';
import { ParticipantsInput } from '@jurnee/common/src/components/ParticipantsInput';
import { BookingCreateBody } from '@jurnee/common/src/dtos/bookings';
import { PropositionsGroupCreateBody } from '@jurnee/common/src/dtos/propositionsGroups';
import { DEFAULT_UTC_TIMEZONE } from '@jurnee/common/src/entities/Address';
import { BookingJSON } from '@jurnee/common/src/entities/Booking';
import { BudgetJSON } from '@jurnee/common/src/entities/Budget';
import { PlaceAddressJSON, PlaceJSON } from '@jurnee/common/src/entities/Place';
import { getAddressFromPlaceAddress } from '@jurnee/common/src/utils/addresses';
import { convertToTimezone, getCurrentTimeZone } from '@jurnee/common/src/utils/dates';
import { isEmpty } from '@jurnee/common/src/utils/strings';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PrimaryButton, SecondaryButton } from 'src/components/buttons';
import { setHoursFromTime } from 'src/utils/date';
import { ExperienceCategory } from 'src/utils/experiences';
import { NameInput } from '../../../components/Booking/NameInput';
import { LocationSelector } from '../../../components/BookingItem/LocationSelector';
import { ProviderSelector } from '../../../components/BookingItem/ProviderSelector';
import { CommentTextarea } from './CommentTextarea';
import { DateTimeSelector } from './DateTimeSelector';

export interface CustomItemFormData {
  propositionsGroup: PropositionsGroupCreateBody;
  booking?: Pick<BookingCreateBody, 'name' | 'budgetId'>;
}

interface Props {
  type: ExperienceCategory['type'];
  sessionToken: string;
  booking: BookingJSON;
  budgets: BudgetJSON[];
  onSubmit(data: CustomItemFormData): Promise<void>;
  onClose(): void;
}

export function Form(props: Props) {
  const { t } = useTranslation(['common', 'booking']);

  const [isSaving, setIsSaving] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const [name, setName] = useState<string>(null);
  const [budgetId, setBudgetId] = useState<number>(null);

  const [isVirtual, setIsVirtual] = useState(false);
  const [cityAddress, setCityAddress] = useState<PlaceAddressJSON>(null);
  const [partnerPlace, setPartnerPlace] = useState<Partial<PlaceJSON>>(null);

  const [date, setDate] = useState<Date>(null);
  const [time, setTime] = useState<string>('');
  const [isFlexibleDate, setIsFlexibleDate] = useState(false);
  const [flexibleDateComment, setFlexibleDateComment] = useState<string>(null);

  const [participants, setParticipants] = useState<number>(null);
  const [comment, setComment] = useState<string>(null);

  function isSubmitDisabled() {
    if (participants <= 0) {
      return true;
    }

    if (!date) {
      return true;
    }

    if (isEmpty(time)) {
      return true;
    }

    if (!props.booking && !name) {
      return true;
    }

    if (!cityAddress) {
      return true;
    }

    if (props.type === 'SPECIFIC_PARTNER' && !partnerPlace) {
      return true;
    }

    if (isFlexibleDate && isEmpty(flexibleDateComment)) {
      return true;
    }

    return isEmpty(comment);
  }

  function getContent() {
    const content = [comment];

    if (isFlexibleDate) {
      content.push('---', 'Flexible date:', flexibleDateComment);
    }

    return content.join('\n\n');
  }

  async function onSubmit() {
    setIsSaving(true);
    setIsSubmitted(true);

    const dateTime = setHoursFromTime(date, time);
    const dateUTC = convertToTimezone(dateTime, getCurrentTimeZone(), DEFAULT_UTC_TIMEZONE);

    const propositionsGroup: PropositionsGroupCreateBody = {
      address: !partnerPlace ? getAddressFromPlaceAddress(cityAddress) : null,
      content: getContent(),
      from: dateUTC,
      participants,
      providerPlaceId: partnerPlace?.id
    };

    if (props.booking) {
      await props.onSubmit({ propositionsGroup });
    } else {
      await props.onSubmit({ booking: { name, budgetId }, propositionsGroup });
    }

    setIsSaving(false);
    setIsSubmitted(false);
  }

  function onCityChange(placeAddress: PlaceAddressJSON) {
    setCityAddress(placeAddress);
    setPartnerPlace(null);
  }

  return (
    <VStack w="100%" spacing={4}>
      {
        !props.booking &&
          <HStack w="100%" spacing={5}>
            <NameInput
              defaultValue={props.booking?.name}
              isInvalid={isSubmitted && isEmpty(name)}
              onChange={setName}
            />

            {
              props.budgets.length > 0 &&
                <BudgetSelect
                  size="sm"
                  label={t('common:fields.budget.label')}
                  placeholder={t('common:fields.budget.placeholder')}
                  budgets={props.budgets}
                  onChange={setBudgetId}
                />
            }
          </HStack>
      }

      <LocationSelector
        type={props.type}
        isVirtual={isVirtual}
        onCityChange={onCityChange}
        onVirtualChange={setIsVirtual}
      />

      {
        props.type === 'SPECIFIC_PARTNER' &&
          <ProviderSelector
            sessionToken={props.sessionToken}
            cityAddress={cityAddress}
            partnerPlace={partnerPlace}
            onChange={setPartnerPlace}
          />
      }

      <DateTimeSelector
        date={date}
        comment={flexibleDateComment}
        isFlexibleDate={isFlexibleDate}
        onDateChange={setDate}
        onTimeChange={setTime}
        onIsFlexibleChange={setIsFlexibleDate}
        onCommentChange={setFlexibleDateComment}
      />

      <ParticipantsInput
        label={t('common:fields.participants.label')}
        onChange={setParticipants}
        isParticipantsRangeValid={!participants || participants > 0}
        isRequired={true}
      />

      <CommentTextarea
        isInvalid={isSubmitted && isEmpty(comment)}
        onChange={setComment}
      />

      <HStack w="100%" spacing={5} mt={1}>
        <SecondaryButton
          w="50%"
          size="sm"
          colorScheme="gray"
          onClick={props.onClose}
        >
          { t('common:buttons.close') }
        </SecondaryButton>

        <PrimaryButton
          w="50%"
          size="sm"
          colorScheme="black"
          onClick={onSubmit}
          isDisabled={isSubmitDisabled()}
          isLoading={isSaving}
        >
          { t('booking:modals.customItem.submit') }
        </PrimaryButton>
      </HStack>
    </VStack>
  );
}