import { Select, SelectProps } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

interface Props {
  onChange(key: string): void;
}

const ROLES = [{
  key: 'ADMIN',
  value: 'ADMIN'
}, {
  key: 'EVENT_MANAGER',
  value: 'EVENT_MANAGER'
}, {
  key: 'ORGANIZER',
  value: 'ORGANIZER'
}, {
  key: 'PARTICIPANT',
  value: ''
}];

export function RoleSelect({ onChange, ...props }: Props & Omit<SelectProps, 'onChange'>) {
  const { t } = useTranslation('employees', { keyPrefix: 'role' });

  return (
    <Select
      {...props}
      size="sm"
      bg="white"
      defaultValue={props.defaultValue || ''}
      onChange={({ target }) => onChange(target.value)}
    >
      { ROLES.map(({ key, value }) => <option key={key} value={value}>{t(key)}</option>) }
    </Select>
  );
}