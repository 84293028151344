import { api } from '@jurnee/common/src/browser/Api';
import { PlaceDescriptionContextBody, PlacesAutocompleteBody } from '@jurnee/common/src/dtos/places';
import { List } from '@jurnee/common/src/serializers';
import { PlaceAddressJSON, PlaceJSON, PlacePredictionJSON } from '../entities/Place';

export function getPlacesAutoComplete(payload: PlacesAutocompleteBody): Promise<List<PlacePredictionJSON, never>> {
  return api.post(`places/autocomplete`, payload);
}

export function getPlaceAddress(placeId: string): Promise<PlaceAddressJSON> {
  return api.get(`places/${placeId}/address`);
}

export function getPlaceDescription(placeId: string, payload: PlaceDescriptionContextBody): Promise<{ content: string }> {
  return api.post(`places/${placeId}/description`, payload);
}

export function getPlace(placeId: string, type: string): Promise<PlaceJSON | { experienceId: number }> {
  return api.get(`places/${placeId}?type=${type}`);
}