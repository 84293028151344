import { Box, HStack, useToast, VStack } from '@chakra-ui/react';
import { getLanguages } from '@jurnee/common/src/api/languages';
import { getPlace, getPlaceDescription } from '@jurnee/common/src/api/places';
import { ExperienceDescription } from '@jurnee/common/src/components/ExperienceDetails/Description';
import { EventStep, RadioValue } from '@jurnee/common/src/components/ExperienceDetails/Form/EventStep';
import { FormTabs } from '@jurnee/common/src/components/ExperienceDetails/Form/FormTabs';
import { DEFAULT_PARTICIPANTS, InfoStep } from '@jurnee/common/src/components/ExperienceDetails/Form/InfoStep';
import { ExperienceHeader } from '@jurnee/common/src/components/ExperienceDetails/Header';
import { ExperienceInfoAndPolicies } from '@jurnee/common/src/components/ExperienceDetails/InfoAndPolicies';
import { ExperienceMap } from '@jurnee/common/src/components/ExperienceDetails/Map';
import { ExperienceReviews } from '@jurnee/common/src/components/ExperienceDetails/Reviews';
import { Loader } from '@jurnee/common/src/components/Loader';
import { PropositionsGroupCreateBody } from '@jurnee/common/src/dtos/propositionsGroups';
import { DEFAULT_UTC_TIMEZONE } from '@jurnee/common/src/entities/Address';
import { BudgetJSON } from '@jurnee/common/src/entities/Budget';
import { ExperienceJSON } from '@jurnee/common/src/entities/Experience';
import { LanguageJSON } from '@jurnee/common/src/entities/Language';
import { PlaceJSON } from '@jurnee/common/src/entities/Place';
import { getAddressFromPlaceAddress } from '@jurnee/common/src/utils/addresses';
import { sortBy } from '@jurnee/common/src/utils/arrays';
import { convertToTimezone, getCurrentTimeZone } from '@jurnee/common/src/utils/dates';
import { getExperiencePath } from '@jurnee/common/src/utils/experiences';
import { isEmpty } from '@jurnee/common/src/utils/strings';
import { getErrorToast, getSuccessToast } from '@jurnee/common/src/utils/toasts';
import { isAdmin } from '@jurnee/common/src/utils/user';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Params, useNavigate, useParams } from 'react-router-dom';
import { CustomItemFormData } from 'src/modals/CustomItemModal/Form';
import { useAppDispatch } from 'src/store';
import { getEditableBookingsSelector } from 'src/store/bookings/bookings.selectors';
import { createBooking } from 'src/store/bookings/bookings.thunks';
import { getBudgets, getUserBudgets } from 'src/store/budgets/budgets.thunks';
import { createPropositionsGroupThunk } from 'src/store/propositionsGroups/propositionsGroups.thunks';
import { getUserSelector } from 'src/store/user/user.selectors';
import { setHoursFromTime } from 'src/utils/date';

export function PlaceDetails() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const toast = useToast();
  const { t } = useTranslation('experience', { keyPrefix: 'toasts' });
  const { id, type } = useParams<Params<'id' | 'type'>>();
  const experienceType = type.toLocaleUpperCase().replace('-', '_') as ExperienceJSON['type'];

  const user = useSelector(getUserSelector);
  const draftBookings = useSelector(getEditableBookingsSelector);

  const [place, setPlace] = useState<PlaceJSON>(null);
  const [languages, setLanguages] = useState<LanguageJSON[]>([]);
  const [areLanguagesLoading, setAreLanguagesLoading] = useState(true);
  const [budgets, setBudgets] = useState<BudgetJSON[]>([]);
  const [areBudgetsLoading, setAreBudgetsLoading] = useState(true);

  const [name, setName] = useState(null);
  const [date, setDate] = useState(null);
  const [participants, setParticipants] = useState(DEFAULT_PARTICIPANTS);
  const [languageId, setLanguageId] = useState(null);
  const [bookingId, setBookingId] = useState(null);
  const [selectedRadio, setSelectedRadio] = useState<RadioValue>('EXISTING_EVENT');
  const [budgetId, setBudgetId] = useState(null);
  const [comment, setComment] = useState(null);
  const [from, setFrom] = useState(null);
  const [description, setDescription] = useState(null);
  const [isDescriptionLoading, setIsDescriptionLoading] = useState(true);

  const isLoading = !place || areLanguagesLoading || areBudgetsLoading;
  const isInfoStepSubmitDisabled = !date || !from || !languageId || participants <= 0;
  const isEventStepSubmitDisabled = (draftBookings.length > 0 && selectedRadio === 'EXISTING_EVENT' && !bookingId) || isEmpty(comment);

  async function init() {
    try {
      const place = await getPlace(id, type.toUpperCase().replace('-', '_'));

      if ('experienceId' in place) {
        return navigate(getExperiencePath({ id: place.experienceId, type: experienceType }));
      }

      setPlace(place);
      setName(place.name);

      fetchDescription(place);
      fetchLanguages();
      fetchBudgets();
    } catch(error) {
      toast(getErrorToast(t('fetchPlace.error')));
    }
  }

  async function fetchLanguages() {
    try {
      const { list } = await getLanguages();
      setLanguages(list);
      setAreLanguagesLoading(false);
    } catch(error) {
      toast(getErrorToast(t('fetchLanguages.error')));
    }
  }

  async function fetchBudgets() {
    try {
      const { list } = await dispatch(isAdmin(user) ? getBudgets() : getUserBudgets()).unwrap();
      const budgets = list.filter(budget=> budget.budgetsOrganizers.map(({ userId }) => userId).includes(user.id));
      setBudgets(budgets);
      setAreBudgetsLoading(false);
    } catch(error) {
      toast(getErrorToast(t('fetchBudgets.error'), error.message));
    }
  }

  async function fetchDescription(place: PlaceJSON) {
    try {
      const { content } = await getPlaceDescription(place.id, {
        name: place.name,
        types: place.types,
        formattedAddress: place.formattedAddress,
        reviews: place.reviews.map(({ comment }) => comment),
        location: {
          lat: place.address.latitude,
          long: place.address.longitude
        }
      });

      setDescription(content);
      setIsDescriptionLoading(false);
    } catch(error) {
      console.log('err', error);
      toast(getErrorToast(t('fetchDescription.error'), error.message));
    }
  }

  useEffect(() => {
    if (!['BAR', 'IN_PERSON', 'RESTAURANT'].includes(experienceType)) {
      return;
    }

    init();
  }, [id]);

  if (isLoading) {
    return <Loader />;
  }

  async function dispatchCreatePropositionsGroup({ propositionsGroup }: CustomItemFormData) {
    return dispatch(
      createPropositionsGroupThunk({
        bookingId,
        data: propositionsGroup
      })
    ).unwrap();
  }

  async function dispatchCreateBooking({ booking, propositionsGroup }: CustomItemFormData) {
    return dispatch(
      createBooking({
        body: {
          name: booking.name,
          budgetId: booking.budgetId || null,
          origin: 'DASHBOARD_BOOKING_FORM',
          propositionsGroups: [propositionsGroup]
        }
      })
    ).unwrap();
  }

  async function onSubmit() {
    try {
      const dateTime = setHoursFromTime(date, from);
      const dateUTC = convertToTimezone(dateTime, getCurrentTimeZone(), DEFAULT_UTC_TIMEZONE);

      const propositionsGroup: PropositionsGroupCreateBody = {
        address: getAddressFromPlaceAddress(place.address),
        content: comment,
        from: dateUTC,
        participants,
        providerPlaceId: place.id
      };

      const { data, relationships } = selectedRadio === 'EXISTING_EVENT' && bookingId ?
        await dispatchCreatePropositionsGroup({ propositionsGroup }) :
        await dispatchCreateBooking({ booking: { name, budgetId }, propositionsGroup });

      toast(getSuccessToast(t('sendRequest.success')));

      const search = new URLSearchParams({
        tab: 'requests',
        propositionsGroupId: sortBy(relationships.propositionsGroups, 'id').at(-1).id.toString()
      });

      navigate({
        pathname: `/bookings/${data.id}`,
        search: search.toString()
      });
    } catch(error) {
      toast(getErrorToast(t('sendRequest.error'), error.message));
    }
  }

  const partnerImageUrl = place.photos.length > 0 ? place.photos[0].path : null;

  return (
    <main>
      <VStack maxW="1184px" mx="auto" position="relative" p={8} spacing={8} alignItems="flex-start">
        <ExperienceHeader
          title={place.name}
          images={place.photos}
        />

        <HStack spacing={16} w="100%" alignItems="stretch">
          <ExperienceDescription
            description={{ content: description, isGenerated: true }}
            maxDuration={0}
            maxParticipants={0}
            type={'IN_PERSON'}
            highlights={[]}
            included={[]}
            requirements={[]}
            partnerImageUrl={partnerImageUrl}
            partnerName={place.name}
            partnerDescription={null}
            partnersProvidersInformation={[{ ratingAverage: place.rating.average, ratingCount: place.rating.count }]}
            isLoading={isDescriptionLoading}
          />

          <Box width="500px">
            <FormTabs>
              <InfoStep
                type={'IN_PERSON'}
                products={[]}
                productId={null}
                participants={participants}
                targetCurrency={user.currency}
                date={date}
                languages={languages}
                languageId={languageId}
                isSubmitDisabled={isInfoStepSubmitDisabled}
                onParticipantsChange={setParticipants}
                onDateChange={setDate}
                onTimeChange={({ from }) => setFrom(from)}
                onLanguageChange={setLanguageId}
              />

              <EventStep
                participants={participants}
                budgets={budgets}
                draftBookings={draftBookings}
                name={name}
                bookingId={bookingId}
                targetCurrency={user.currency}
                selectedRadio={selectedRadio}
                isCommentRequired={true}
                isSubmitDisabled={isEventStepSubmitDisabled}
                onNameChange={setName}
                onBookingChange={setBookingId}
                onBudgetChange={setBudgetId}
                onCommentChange={setComment}
                onRadioSelect={setSelectedRadio}
                onSubmit={onSubmit}
              />
            </FormTabs>
          </Box>
        </HStack>

        <ExperienceMap address={getAddressFromPlaceAddress(place.address)} />

        {
          place.reviews.length > 0 &&
            <ExperienceReviews rating={place.rating} reviews={place.reviews} />
        }

        <ExperienceInfoAndPolicies
          importantInformation={[]}
          wheelchairAccessible={false}
          pregnantAccessible={false}
        />
      </VStack>
    </main>
  );
}