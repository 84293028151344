import { DotMenu } from '@jurnee/common/src/components/DotMenu';
import { MenuItemWrapper } from '@jurnee/common/src/components/MenuItemWrapper';
import { ApprovalProcessJSON } from '@jurnee/common/src/entities/ApprovalProcess';
import { BudgetJSON } from '@jurnee/common/src/entities/Budget';
import { UserDetails } from '@jurnee/common/src/entities/User';
import { ConfirmModal } from '@jurnee/common/src/modals/ConfirmModal';
import { Trans, useTranslation } from 'react-i18next';
import EditEmployeeDrawer from 'src/drawers/EditEmployeeDrawer';

interface Props {
  currentUser: UserDetails;
  approvalProcesses: ApprovalProcessJSON[];
  budgets: BudgetJSON[];
  user: UserDetails;
  onDeleteEmployee(user: UserDetails): void;
  onSendInvitation(user: UserDetails): void;
}

export function EditMenu(props: Props) {
  const { t } = useTranslation('people', { keyPrefix: 'dotMenu' });
  const { user, currentUser, onDeleteEmployee, onSendInvitation } = props;

  const hasSendInvitation = !user.loggedIn && user.usersRoles.length > 0;

  return (
    <DotMenu size="sm" color="black" placement="bottom-end">
      <EditEmployeeDrawer user={user} budgets={props.budgets}>
        <MenuItemWrapper icon="edit" label={t('edit')} />
      </EditEmployeeDrawer>
      {
        currentUser.id !== user.id &&
          <ConfirmModal
            title={t('deleteConfirmation.title')}
            message={<Trans i18nKey="people:dotMenu.deleteConfirmation.message" values={{ email: user.email }} shouldUnescape />}
            buttonLabel={t('delete')}
            onConfirm={() => onDeleteEmployee(user)}
          >
            <MenuItemWrapper icon="trash" label={t('delete')} />
          </ConfirmModal>
      }
      {
        hasSendInvitation &&
          <MenuItemWrapper icon="sendMail" label={t('resendInvitation')} onClick={() => onSendInvitation(user)}/>
      }
    </DotMenu>
  );
}