import { StackProps, Text, VStack } from '@chakra-ui/react';
import { BookingJSON } from '@jurnee/common/src/entities/Booking';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { CustomItemModal } from 'src/modals/CustomItemModal';
import { getCompanySelector } from 'src/store/company/company.selector';
import { getExperienceCategories } from 'src/utils/experiences';
import { IconTitle } from '../IconTitle';
import { ExperienceCategoryCard } from './ExperienceCategoryCard';

interface Props {
  booking: BookingJSON;
}

export function Catalog({ booking, ...inheritedProps }: Props & StackProps) {
  const{ t } = useTranslation('experiences');

  const { excludedCatalogs } = useSelector(getCompanySelector);

  const categories = useMemo(
    () => getExperienceCategories({ excludedCatalogs: [...excludedCatalogs, 'BOX_SHIPPING'] }),
    [excludedCatalogs]
  );

  return (
    <VStack p={5} display="flex" h="100%" gap={3} bg="white" border="1px solid" borderColor="gray.200" borderRadius={4} {...inheritedProps}>
      <IconTitle colorScheme="gray" icon="search" label={t('explore.title')} />

      <Text color="gray.400">{t('explore.subtitle')}</Text>

      <VStack w="100%" display="flex" h="100%" spacing="16px" justifyContent="space-between">
        {
          categories.map(category =>
            category.path ? (
              <NavLink
                key={category.type}
                to={category.path}
                state={{ booking }}
                style={{ width: '100%' }}
              >
                <ExperienceCategoryCard
                  category={category}
                />
              </NavLink>
            ) : (
              <CustomItemModal
                key={category.type}
                type={category.type}
                booking={booking}
              >
                <ExperienceCategoryCard
                  category={category}
                />
              </CustomItemModal>
            )
          )
        }
      </VStack>
    </VStack>
  );
}