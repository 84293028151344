import { Box } from '@chakra-ui/react';
import { Icon } from '@jurnee/common/src/components/Icon';
import { LatLong, LocationCircle, LocationRectangle } from '@jurnee/common/src/dtos/places';
import { ExperienceJSON } from '@jurnee/common/src/entities/Experience';
import { PlaceJSON } from '@jurnee/common/src/entities/Place';
import { getCdnImageUrl } from '@jurnee/common/src/utils/core';
import { getExperiencePath } from '@jurnee/common/src/utils/experiences';
import { formatPriceInCurrency } from '@jurnee/common/src/utils/prices';
import { getProductWithMinUnitPrice, isCustomRequestProduct } from '@jurnee/common/src/utils/products';
import { Feature, FeatureCollection, Point } from 'geojson';
import mapbox, { GeoJSONFeature, GeoJSONSource, LngLat, MapMouseEvent, Popup, PopupOptions } from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import { useEffect, useMemo, useRef, useState } from 'react';
import { createRoot } from 'react-dom/client';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { PrimaryButton } from 'src/components/buttons';
import { PopupContent } from 'src/components/Experience/ExperienceCard/PopupContent';
import { getExperiencesRatingsSelector } from 'src/store/experiencesRatings/experiencesRatings.selectors';
import { getUserSelector } from 'src/store/user/user.selectors';
import { filterDuplicatePlaces } from 'src/utils/places';
import { clusterCountLayer, clustersLayer, pointsLayer } from './layers';

export interface Properties {
  id: number | string;
  title: string;
  src: string;
  to: string;
  ratingAverage: number;
  ratingCount: number;
  address: string;
  price?: string;
}

interface Props extends LatLong {
  experiences: ExperienceJSON[];
  places: PlaceJSON[];
  hoverId: number | string;
  moveMap: boolean;
  isLoading: boolean;
  onSearch(): void;
  onMoveEnd(location: LocationCircle & LocationRectangle): void;
  onBoundsChange(location: LocationCircle & LocationRectangle): void;
};

type MouseEvent = MapMouseEvent & { features: (GeoJSONFeature & Feature<Point, Properties>)[] };
type FeatureType = 'point' | 'cluster';

const MAP_STYLE = 'mapbox://styles/dammmmien/cm5mjyhre00ap01s9denmak3h';
const MIN_ZOOM = 10.5;
const MAX_ZOOM = 15;
const CLUSTER_RADIUS = 20;

mapbox.accessToken = process.env.MAPBOX_ACCESS_TOKEN;

function getPopupOffset(featureType: FeatureType): PopupOptions['offset'] {
  const pointRadius = 6;
  const clusterRadius = 14;
  const cardOffset = 6;

  const markerRadius = featureType === 'cluster' ? clusterRadius : pointRadius;
  const margin = markerRadius + cardOffset;
  const linearOffset = margin / 2;

  return {
    'top': [0, margin],
    'top-left': [linearOffset, linearOffset],
    'top-right': [-linearOffset, linearOffset],
    'bottom': [0, -margin],
    'bottom-left': [linearOffset, -linearOffset],
    'bottom-right': [-linearOffset, -linearOffset],
    'left': [margin, 0],
    'right': [-margin, 0]
  };
}

export function ExperiencesMap(props: Props) {
  const { t } = useTranslation('experiences', { keyPrefix: 'map' });

  const mapContainerRef = useRef();
  const mapRef = useRef<mapboxgl.Map>();
  const popupRef = useRef<Popup>(null);

  const { currency } = useSelector(getUserSelector);
  const experiencesRatings = useSelector(getExperiencesRatingsSelector);

  const [currentHovered, setCurrentHovered] = useState(null);

  const filteredExperiences = useMemo(() => props.experiences.filter(e => e.partner?.address?.lat && e.partner?.address?.long), [props.experiences]);
  const filteredPlaces = useMemo(() => filterDuplicatePlaces(props.places, filteredExperiences), [props.places, filteredExperiences]);

  const geojson = useMemo(() => {
    const collection = { type: 'FeatureCollection', features: [] } as FeatureCollection<Point, Properties>;

    if (props.isLoading) {
      return collection;
    }

    return [...filteredExperiences, ...filteredPlaces].reduce((out, data) => {
      out.features.push('type' in data ? buildExperienceFeature(data) : buildPlaceFeature(data));
      return out;
    }, collection);
  }, [filteredExperiences, filteredPlaces, props.isLoading]);

  useEffect(() => {
    if (!mapRef.current) {
      return;
    }

    const source = mapRef.current.getSource('experiences') as GeoJSONSource;

    if (!source) {
      return;
    }

    source.setData(geojson);

    fitBoundsOnFeatures();
  }, [geojson.features, mapRef.current]);

  useEffect(() => {
    if (!mapRef.current) {
      return;
    }

    if (!props.moveMap) {
      return;
    }

    mapRef.current.setCenter([props.long, props.lat]);
    mapRef.current.setZoom(MIN_ZOOM);

    const location = getLocation(mapRef.current);
    props.onBoundsChange(location);
  }, [mapRef.current, props.lat, props.long, props.moveMap]);

  function fitBoundsOnFeatures() {
    if (geojson.features.length === 0) {
      return;
    }

    const coordinates = geojson.features.map(({ geometry }) => geometry.coordinates) as [number, number][];

    const bounds = coordinates.reduce((bounds, coord) => {
      return [
        Math.min(bounds[0], coord[0]), // Min long
        Math.min(bounds[1], coord[1]), // Min lat
        Math.max(bounds[2], coord[0]), // Max long
        Math.max(bounds[3], coord[1]), // Max lat
      ] as [number, number, number, number];
    }, [Infinity, Infinity, -Infinity, -Infinity] as [number, number, number, number]);

    mapRef.current.fitBounds(bounds, {
      padding: 60,
      duration: 150,
      essential: true
    });
  }

  function buildExperienceFeature(data: ExperienceJSON): Feature<Point, Properties> {
    const src = data.experiencesImages.length > 0 ? getCdnImageUrl(data.experiencesImages[0].image.path) : '';
    const experienceRating = experiencesRatings.find(({ experienceId }) => experienceId === data.id);
    const address = data.partner?.address ? `${data.partner?.address.city} ${data.partner?.address.postalCode}` : null;
    const products = data.products.filter(product => !isCustomRequestProduct(product));
    const product = getProductWithMinUnitPrice(products);
    const price = product?.minPricePerUnit ? formatPriceInCurrency({ value: product.minPricePerUnit, currency: product.currency, targetCurrency: currency }) : null;
    const coordinates = [data.partner.address.long, data.partner.address.lat];

    return {
      type: 'Feature',
      properties: {
        id: data.id,
        title: data.name,
        src,
        to: getExperiencePath(data),
        ratingAverage: experienceRating?.average ?? 0,
        ratingCount: experienceRating?.count ?? 0,
        address,
        price
      },
      geometry: {
        type: 'Point',
        coordinates
      }
    };
  }

  function buildPlaceFeature(data: PlaceJSON): Feature<Point, Properties> {
    const src = data.photos.length > 0 ? data.photos[0].path : '';
    const addressParts = data.formattedAddress.split(',');
    const address = addressParts.splice(-2)[0];
    const coordinates = [data.address.longitude, data.address.latitude];

    return {
      type: 'Feature',
      properties: {
        id: data.id,
        title: data.name,
        src,
        to: `/places/${data.id}`,
        ratingAverage: data.rating.average,
        ratingCount: data.rating.count,
        address
      },
      geometry: {
        type: 'Point',
        coordinates
      }
    };
  }

  function showPopup(feature: Feature<Point, Properties>, type: FeatureType = 'point') {
    popupRef.current?.remove();

    const coordinates = feature.geometry.coordinates.slice();

    const div = document.createElement('div');
    const root = createRoot(div);
    root.render(<PopupContent {...feature.properties} />);

    popupRef.current = new mapbox.Popup({
      closeButton: false,
      closeOnClick: false,
      className: 'experiences-map-marker-card',
      maxWidth: '320px',
      offset: getPopupOffset(type)
    }).setDOMContent(div)
      .setLngLat(coordinates as [number, number])
      .addTo(mapRef.current);
  }

  function getLocation(map: mapboxgl.Map) {
    const bounds = map.getBounds();
    const sw = bounds.getSouthWest();
    const ne = bounds.getNorthEast();

    const center = map.getCenter();
    const dist1 = center.distanceTo(new LngLat(center.lng, ne.lat));
    const dist2 = center.distanceTo(new LngLat(sw.lng, center.lat));
    const radius = Math.floor(Math.min(dist1, dist2));

    return {
      circle: {
        lat: center.lat,
        long: center.lng,
        radius
      },
      rectangle: {
        sw: {
          lat: sw.lat,
          long: sw.lng
        },
        ne: {
          lat: ne.lat,
          long: ne.lng
        }
      }
    };
  }

  useEffect(() => {
    mapRef.current = new mapbox.Map({
      container: mapContainerRef.current,
      style: MAP_STYLE,
      center: { lng: props.long, lat: props.lat },
      zoom: MIN_ZOOM,
      minZoom: MIN_ZOOM,
      maxZoom: MAX_ZOOM,
      fadeDuration: 0,
    });

    mapRef.current.addControl(new mapbox.NavigationControl({ showCompass: false }));

    let hoverId: number | string = null;

    mapRef.current.on('load', () => {
      mapRef.current.addSource('experiences', {
        type: 'geojson',
        data: geojson,
        cluster: true,
        clusterRadius: CLUSTER_RADIUS,
        generateId: true
      });

      mapRef.current.addLayer(clustersLayer);
      mapRef.current.addLayer(clusterCountLayer);
      mapRef.current.addLayer(pointsLayer);

      const location = getLocation(mapRef.current);
      props.onBoundsChange(location);
    });

    mapRef.current.on('mouseenter', 'points', (e: MouseEvent) => {
      mapRef.current.getCanvas().style.cursor = 'pointer';

      const [feature] = e.features;
      mapRef.current.setFeatureState({ source: 'experiences', id: feature.id }, { hover: true });
      hoverId = feature.id;

      showPopup(feature);
    });

    mapRef.current.on('mouseleave', 'points', () => {
      mapRef.current.getCanvas().style.cursor = '';
      mapRef.current.setFeatureState({ source: 'experiences', id: hoverId }, { hover: false });
      popupRef.current.remove();
    });

    mapRef.current.on('click', 'points', (e: MouseEvent) => {
      const [feature] = e.features;
      window.open(feature.properties.to, '_blank');
    });

    mapRef.current.on('moveend', ({ target }) => {
      const location = getLocation(target);
      props.onMoveEnd(location);
    });

    return () => mapRef.current.remove();
  }, []);

  useEffect(() => {
    if (!mapRef.current || !mapRef.current.isStyleLoaded()) {
      return;
    }

    if (!props.hoverId) {
      popupRef.current?.remove();
      setCurrentHovered(null);
    }

    const features = mapRef.current.queryRenderedFeatures({ layers: ['points', 'clusters'] });
    const source = mapRef.current.getSource('experiences') as GeoJSONSource;

    let hoveredFeature = null;

    features.forEach(feature => {
      const isCluster = feature.properties?.cluster;

      if (isCluster) {
        const clusterId = feature.properties.cluster_id;

        source.getClusterLeaves(clusterId, Infinity, 0, (_, clusterFeatures) => {
          const hovered = clusterFeatures.find(({ properties }) => properties?.id === props.hoverId);
          mapRef.current.setFeatureState({ source: 'experiences', id: clusterId }, { hover: !!hovered });

          if (!!hovered && currentHovered?.id !== props.hoverId) {
            const cluster = {
              ...hovered,
              geometry: feature.geometry
            } as unknown as Feature<Point, Properties>;

            showPopup(cluster, 'cluster');
            setCurrentHovered(hovered);
          }
        });
      } else {
        const hover = feature.properties?.id === props.hoverId;
        mapRef.current.setFeatureState({ source: 'experiences', id: feature.id }, { hover });

        if (hover && currentHovered?.id !== props.hoverId) {
          hoveredFeature = feature;
        }
      }
    });

    if (hoveredFeature) {
      showPopup(hoveredFeature as unknown as Feature<Point, Properties>);
      setCurrentHovered(hoveredFeature);
    } else {
      popupRef.current?.remove();
      setCurrentHovered(null);
    }
  }, [props.hoverId]);

  return (
    <>
      <Box id="map" ref={mapContainerRef} width="100%" height="100%" />

      <Box w="100%" marginTop="-52px" textAlign="center">
        <PrimaryButton size="sm" colorScheme="black" gap={2} onClick={props.onSearch}>
          <Icon icon="search" color="white" size={4} />
          { t('searchInArea') }
        </PrimaryButton>
      </Box>
    </>
  );
}