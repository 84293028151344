import { HStack, Text, VStack } from '@chakra-ui/react';
import { ApprovalRuleJSON } from '@jurnee/common/src/entities/ApprovalRule';
import { UserJSON } from '@jurnee/common/src/entities/User';
import { getUserLabel } from '@jurnee/common/src/utils/user';
import { useMemo } from 'react';

interface Props {
  users: UserJSON[];
  approvalRules: ApprovalRuleJSON[];
}

export function ApprovalRules(props: Props) {
  const approvers = useMemo(() => {
    return props.approvalRules.map(({ approverId }) => props.users.find(({ id }) => id === approverId));
  }, [props.approvalRules, props.users]);

  return (
    <VStack w="100%" spacing="10px">
      {
        approvers.map((approver, i) => (
          <HStack key={i} w="100%" pl={3} paddingY="10px" borderLeft="4px solid" borderColor="gray.200" justifyContent="space-between">
            <Text fontSize={14} noOfLines={1} fontWeight={700}>{getUserLabel(approver)}</Text>
            <Text fontSize={14} noOfLines={1} color="gray.400">{approver.email}</Text>
          </HStack>
        ))
      }
    </VStack>
  );
}