import ReviewEntity, { ReviewProvider } from '@jurnee/common/src/entities/Review';
import { isEmpty } from '@jurnee/common/src/utils/strings';

interface ProviderReview {
  author: string;
  avatar: string | null;
  publishedAt: Date;
  rating: number;
  text: string;
  origin: string;
}

export function getReviewAuthor(review: Pick<ReviewEntity, 'authorName'>) {
  return review && !isEmpty(review.authorName) ? review.authorName : 'Anonymous';
}

export function buildFromProviderReview(review: ProviderReview) {
  return {
    author: review.author ?? 'Anonymous',
    comment: review.text,
    createdAt: review.publishedAt,
    provider: review.origin as ReviewProvider,
    rating: review.rating,
  };
}